import React, { useState } from 'react';
import axios from 'axios';

const Search = () => {
    const [netId, setNetId] = useState('');
    const [userData, setUserData] = useState(() => {
        const savedData = sessionStorage.getItem('userData');
        return savedData ? JSON.parse(savedData) : null;
    });
    const [courseData, setCourseData] = useState(null); // Add a new state to hold course data
    const [error, setError] = useState(null);
    
    // frontend
    //      when a user searches for a specific prof's net_id, the following function is triggered
    // backend
    //      when the user enters a net_id and triggers the search in the frontend, handleSearch() sends a GET request
    //      to the Django backend, to the /courses/ endpoint, which is mapped to the get_courses function in views.py
    //
    // production server can do https://mathtools.math.washington.edu/admin because Ubuntu server's Apache already has it set up
    const handleSearch = async () => {
        try {
            const response = await axios.get(`https://mathtools.math.washington.edu/users/${netId}/`);          // production server
            //const response = await axios.get(`http://lyeh30b.math.washington.edu:8000/users/${netId}/`);        // run on my desktop via port 8000
            //const response = await axios.get(`http://127.0.0.1:8000/users/${netId}/`);
            //const response = await axios.get(`https://depts/washington/edu/mathtools/Projects/Teaching_Credit/users/${netId}/`);

            setUserData(response.data);
            setError(null);
    
            console.log("User data fetched: ", response.data);
    
            // Store the user data in session storage
            sessionStorage.setItem('userData', JSON.stringify(response.data));
    
            // Check if the user is an admin and redirect to the Django admin interface
            const adminNetIDs = ['lyeh1234', 'sbsheetz', 'sterrs', 'lieblich', 'mathch', 'jpalmier']; // Add other admin net IDs here
            if (adminNetIDs.includes(netId)) {
                console.log("Admin detected, redirecting to admin interface");
                window.location.href = 'https://mathtools.math.washington.edu/admin/';      // production server
                //window.location.href = 'http://lyeh30b.math.washington.edu:8000/admin/';    // desktop port 8000
                //window.location.href = 'http://127.0.0.1:8000/admin/';
                //window.location.href = 'https://depts/washington/edu/mathtools/Projects/Teaching_Credit/admin/';
                return; // Stop further processing for admins
            }
    
            // Check for the correct user type (tenure, teaching, postdoc, etc.) that contains `current_courses`
            let currentCourses = null;
    
            if (response.data.tenure) {
                currentCourses = response.data.tenure.current_courses;
            } else if (response.data.teaching) {
                currentCourses = response.data.teaching.current_courses;
            } else if (response.data.postdoc) {
                currentCourses = response.data.postdoc.current_courses;
            } else if (response.data.retiree) {
                currentCourses = response.data.retiree.current_courses;
            } else if (response.data.grad) {
                currentCourses = response.data.grad.current_courses;
            } else if (response.data.misc) {
                currentCourses = response.data.misc.current_courses;
            }
    
            if (currentCourses) {
                const courseIds = currentCourses.map(course => course.trim()); // Trim any spaces
                //const coursesResponse = await axios.get(`http://127.0.0.1:8000/courses/`, {
                //const coursesResponse = await axios.get(`https://depts/washington/edu/mathtools/Projects/Teaching_Credit/courses/`, {
                //const coursesResponse = await axios.get(`http://lyeh30b.math.washington.edu:8000/courses/`, {     // desktop port 8000
                const coursesResponse = await axios.get(`https://mathtools.math.washington.edu/courses/`, {       // production server
                    params: { 
                        course_ids: courseIds.join(','), // Pass the course_ids as a comma-separated string (i.e. "126 D,134 A,...")
                        net_id: netId                    // Also pass the net_id for identification
                    }
                });
            
                // Log course data to debug
                console.log("Course data from backend: ", coursesResponse.data);
                
                setCourseData(coursesResponse.data);  // Set the course data from the API
            } else {
                console.error("No current courses found for this user.");
            }
        } catch (err) {
            console.error('Error fetching user or course data:', err);
            setError('User not found or an error occurred.');
            setUserData(null);
            setCourseData(null); // Clear course data on error
        }
    };
    
    

    const handleClear = () => {
        sessionStorage.removeItem('userData');
        setUserData(null);
        setCourseData(null);  // Clear the course data as well
        setNetId('');
    };

    const renderCreditTable = () => {
        // Check if userData exists and is from Tenure_Stream & Teaching_Stream
        if (!userData || !(userData.tenure || userData.teaching)) return null;
    
        // Extract credit-related fields from whichever professor type exists
        const professorData = userData.tenure || userData.teaching;
    
        const {
            credit_teach,
            credit_thesis,
            credit_service,
            credit_award,
            credit_hiring,
            credits_current_year,
            credits_at_year_start,
            credits_at_year_end
        } = professorData; // Now it can come from Tenure_Stream & Teaching_Stream
    
        return (
            <div>
                <h2>FACULTY NAME</h2>
                <table border="1">
                    <thead>
                        <tr>
                            <th>Teaching Credit</th>
                            <th>Thesis Credit</th>
                            <th>Service Credit</th>
                            <th>Award Credit</th>
                            <th>Hiring Credit</th>
                            <th>Total Credit</th>
                            <th>2023-24 Debit/Credit</th>
                            <th>2024-25 Debit/Credit</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{credit_teach ? credit_teach.toFixed(2) : '0.00'}</td>
                            <td>{credit_thesis ? credit_thesis.toFixed(2) : '0.00'}</td>
                            <td>{credit_service ? credit_service.toFixed(2) : '0.00'}</td>
                            <td>{credit_award ? credit_award.toFixed(2) : '0.00'}</td>
                            <td>{credit_hiring ? credit_hiring.toFixed(2) : '0.00'}</td>
                            <td>{credits_current_year ? credits_current_year.toFixed(2) : '0.00'}</td>
                            <td>{credits_at_year_start ? credits_at_year_start.toFixed(2) : '0.00'}</td>
                            <td>{credits_at_year_end ? credits_at_year_end.toFixed(2) : '0.00'}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };
    

    const renderCourseTable = () => {
        if (!courseData) return null; // Check if course data exists
    
        const displayedCourses = new Set();  // Set to track duplicates per quarter
    
        const renderCourseRows = () => {
            const maxRows = Math.max(autumnCourses.length, winterCourses.length, springCourses.length);
            const rows = [];
    
            for (let i = 0; i < maxRows; i++) {
                const autumnCourse = autumnCourses[i] || {};
                const winterCourse = winterCourses[i] || {};
                const springCourse = springCourses[i] || {};
    
                // Check if there is any meaningful course data in any quarter for this row
                const hasAutumnCourse = autumnCourse.course_id || autumnCourse.course_days || autumnCourse.course_id_times || autumnCourse.modality_designation;
                const hasWinterCourse = winterCourse.course_id || winterCourse.course_days || winterCourse.course_id_times || winterCourse.modality_designation;
                const hasSpringCourse = springCourse.course_id || springCourse.course_days || springCourse.course_id_times || springCourse.modality_designation;
    
                // Only render a row if at least one quarter has a non-empty course
                if (hasAutumnCourse || hasWinterCourse || hasSpringCourse) {
                    rows.push(
                        <tr key={i}>
                            {hasAutumnCourse && !displayedCourses.has(`${autumnCourse.course_id}-${autumnCourse.course_section_letter}-${autumnCourse.modality_designation}-${autumnCourse.course_qtr}`) ? (
                                <>
                                    <td>{autumnCourse.course_id + autumnCourse.course_section_letter}</td>
                                    <td>{autumnCourse.course_days}</td>
                                    <td>{autumnCourse.course_id_times}</td>
                                    <td>{autumnCourse.modality_designation}</td>
                                </>
                            ) : (
                                <td colSpan="4"></td>
                            )}
                            {hasWinterCourse && !displayedCourses.has(`${winterCourse.course_id}-${winterCourse.course_section_letter}-${winterCourse.modality_designation}-${winterCourse.course_qtr}`) ? (
                                <>
                                    <td>{winterCourse.course_id + winterCourse.course_section_letter}</td>
                                    <td>{winterCourse.course_days}</td>
                                    <td>{winterCourse.course_id_times}</td>
                                    <td>{winterCourse.modality_designation}</td>
                                </>
                            ) : (
                                <td colSpan="4"></td>
                            )}
                            {hasSpringCourse && !displayedCourses.has(`${springCourse.course_id}-${springCourse.course_section_letter}-${springCourse.modality_designation}-${springCourse.course_qtr}`) ? (
                                <>
                                    <td>{springCourse.course_id + springCourse.course_section_letter}</td>
                                    <td>{springCourse.course_days}</td>
                                    <td>{springCourse.course_id_times}</td>
                                    <td>{springCourse.modality_designation}</td>
                                </>
                            ) : (
                                <td colSpan="4"></td>
                            )}
                        </tr>
                    );
    
                    // Track which courses have been displayed
                    if (hasAutumnCourse) displayedCourses.add(`${autumnCourse.course_id}-${autumnCourse.course_section_letter}-${autumnCourse.modality_designation}-${autumnCourse.course_qtr}`);
                    if (hasWinterCourse) displayedCourses.add(`${winterCourse.course_id}-${winterCourse.course_section_letter}-${winterCourse.modality_designation}-${winterCourse.course_qtr}`);
                    if (hasSpringCourse) displayedCourses.add(`${springCourse.course_id}-${springCourse.course_section_letter}-${springCourse.modality_designation}-${springCourse.course_qtr}`);
                }
            }
    
            return rows;
        };
    
        // Split the courses into Autumn, Winter, and Spring based on the course_qtr
        const autumnCourses = courseData.filter(course => course.course_qtr === 'Autumn');
        const winterCourses = courseData.filter(course => course.course_qtr === 'Winter');
        const springCourses = courseData.filter(course => course.course_qtr === 'Spring');
    
        return (
            <div>
                <h2>2024-2025 Teaching Schedule</h2>
                <table border="1">
                    <thead>
                        <tr>
                            <th colSpan="4">Autumn</th>
                            <th colSpan="4">Winter</th>
                            <th colSpan="4">Spring</th>
                        </tr>
                        <tr>
                            <th>Course</th>
                            <th>Day</th>
                            <th>Time</th>
                            <th>Modularity</th>
                            <th>Course</th>
                            <th>Day</th>
                            <th>Time</th>
                            <th>Modularity</th>
                            <th>Course</th>
                            <th>Day</th>
                            <th>Time</th>
                            <th>Modularity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderCourseRows()}
                    </tbody>
                </table>
            </div>
        );
    };
    
    // // THIS TABLE VIEW WORKS
    // const renderCourseTable = () => {
    //     if (!courseData) return null; // Check if course data exists
    
    //     const displayedCourses = new Set();  // Set to track duplicates per quarter
    
    //     const renderCourseRows = () => {
    //         const maxRows = Math.max(autumnCourses.length, winterCourses.length, springCourses.length);
    //         const rows = [];
    
    //         for (let i = 0; i < maxRows; i++) {
    //             const autumnCourse = autumnCourses[i] || {};
    //             const winterCourse = winterCourses[i] || {};
    //             const springCourse = springCourses[i] || {};
    
    //             // Create unique identifiers for each course in each quarter
    //             const uniqueAutumn = `${autumnCourse.course_id}-${autumnCourse.course_section_letter}-${autumnCourse.modality_designation}-${autumnCourse.course_qtr}`;
    //             const uniqueWinter = `${winterCourse.course_id}-${winterCourse.course_section_letter}-${winterCourse.modality_designation}-${winterCourse.course_qtr}`;
    //             const uniqueSpring = `${springCourse.course_id}-${springCourse.course_section_letter}-${springCourse.modality_designation}-${springCourse.course_qtr}`;
    
    //             // Ensure each course is only rendered once per quarter
    //             rows.push(
    //                 <tr key={i}>
    //                     {autumnCourse.course_id && !displayedCourses.has(uniqueAutumn) ? (
    //                         <>
    //                             <td>{autumnCourse.course_id + autumnCourse.course_section_letter}</td>
    //                             <td>{autumnCourse.course_days}</td>
    //                             <td>{autumnCourse.course_id_times}</td>
    //                             <td>{autumnCourse.modality_designation}</td>
    //                         </>
    //                     ) : (
    //                         <td colSpan="4"></td>
    //                     )}
    //                     {winterCourse.course_id && !displayedCourses.has(uniqueWinter) ? (
    //                         <>
    //                             <td>{winterCourse.course_id + winterCourse.course_section_letter}</td>
    //                             <td>{winterCourse.course_days}</td>
    //                             <td>{winterCourse.course_id_times}</td>
    //                             <td>{winterCourse.modality_designation}</td>
    //                         </>
    //                     ) : (
    //                         <td colSpan="4"></td>
    //                     )}
    //                     {springCourse.course_id && !displayedCourses.has(uniqueSpring) ? (
    //                         <>
    //                             <td>{springCourse.course_id + springCourse.course_section_letter}</td>
    //                             <td>{springCourse.course_days}</td>
    //                             <td>{springCourse.course_id_times}</td>
    //                             <td>{springCourse.modality_designation}</td>
    //                         </>
    //                     ) : (
    //                         <td colSpan="4"></td>
    //                     )}
    //                 </tr>
    //             );
    
    //             // Add the unique course to the set to prevent rendering it again within the same quarter
    //             if (uniqueAutumn) displayedCourses.add(uniqueAutumn);
    //             if (uniqueWinter) displayedCourses.add(uniqueWinter);
    //             if (uniqueSpring) displayedCourses.add(uniqueSpring);
    //         }
    
    //         return rows;
    //     };
    
    //     // Split the courses into Autumn, Winter, and Spring based on the course_qtr
    //     const autumnCourses = courseData.filter(course => course.course_qtr === 'Autumn');
    //     const winterCourses = courseData.filter(course => course.course_qtr === 'Winter');
    //     const springCourses = courseData.filter(course => course.course_qtr === 'Spring');
    
    //     return (
    //         <div>
    //             <h2>2024-2025 Teaching Schedule</h2>
    //             <table border="1">
    //                 <thead>
    //                     <tr>
    //                         <th colSpan="4">Autumn</th>
    //                         <th colSpan="4">Winter</th>
    //                         <th colSpan="4">Spring</th>
    //                     </tr>
    //                     <tr>
    //                         <th>Course</th>
    //                         <th>Day</th>
    //                         <th>Time</th>
    //                         <th>Modularity</th>
    //                         <th>Course</th>
    //                         <th>Day</th>
    //                         <th>Time</th>
    //                         <th>Modularity</th>
    //                         <th>Course</th>
    //                         <th>Day</th>
    //                         <th>Time</th>
    //                         <th>Modularity</th>
    //                     </tr>
    //                 </thead>
    //                 <tbody>
    //                     {renderCourseRows()}
    //                 </tbody>
    //             </table>
    //         </div>
    //     );
    // };    


    return (
        <div>
            <input
                type="text"
                value={netId}
                onChange={(e) => setNetId(e.target.value)}
                placeholder="Enter Net ID"
            />
            <button onClick={handleSearch}>Search</button>
            <button onClick={handleClear}>Clear</button>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {userData && (
                <div>
                    {renderCreditTable()}
                    {renderCourseTable()} {/* Render the course table */}
                    <p>If you see any incorrect credit or course details, email <a href="mailto:teachingcredit-support@uw.edu">teachingcredit-support@uw.edu</a> to submit a change request.</p>
                </div>
            )}
        </div>
    );
};

export default Search;





















// import React, { useState } from 'react';
// import axios from 'axios';

// const Search = () => {
//     const [netId, setNetId] = useState('');
//     const [userData, setUserData] = useState(() => {
//         const savedData = sessionStorage.getItem('userData');
//         return savedData ? JSON.parse(savedData) : null;
//     });
//     const [courseData, setCourseData] = useState(null); // Add a new state to hold course data
//     const [error, setError] = useState(null);

//     // const handleSearch = async () => {
//     //     try {
//     //         const response = await axios.get(`http://127.0.0.1:8000/users/${netId}/`);
//     //         setUserData(response.data);
//     //         setError(null);

//     //         // Store the user data in session storage
//     //         sessionStorage.setItem('userData', JSON.stringify(response.data));

//     //         // Fetch course details based on current_courses in userData
//     //         const courseIds = response.data.tenure.current_courses.join(',');
//     //         const coursesResponse = await axios.get(`http://127.0.0.1:8000/courses/?course_ids=${courseIds}`);
//     //         setCourseData(coursesResponse.data);  // Set the course data from the API

//     //         // Redirect admin users
//     //         const adminNetIDs = ['lyeh1234', 'adminNetID2', 'adminNetID3'];
//     //         if (adminNetIDs.includes(netId)) {
//     //             window.location.href = 'http://127.0.0.1:8000/admin/';
//     //         }
//     //     } catch (err) {
//     //         console.error('Error fetching user or course data:', err);
//     //         setError('User not found or an error occurred.');
//     //         setUserData(null); 
//     //         setCourseData(null); // Clear course data on error
//     //     }
//     // };
//     const handleSearch = async () => {
//         try {
//             const response = await axios.get(`http://127.0.0.1:8000/users/${netId}/`);
//             setUserData(response.data);
//             setError(null);
    
//             // Store the user data in session storage
//             sessionStorage.setItem('userData', JSON.stringify(response.data));
    
//             // Fetch course details based on current_courses in userData
//             const courseIds = response.data.tenure.current_courses.join(',');
//             const coursesResponse = await axios.get(`http://127.0.0.1:8000/courses/?course_ids=${courseIds}`);
    
//             console.log("Course data from backend: ", coursesResponse.data); // Log course data to debug
            
//             setCourseData(coursesResponse.data);  // Set the course data from the API
//         } catch (err) {
//             console.error('Error fetching user or course data:', err);
//             setError('User not found or an error occurred.');
//             setUserData(null); 
//             setCourseData(null); // Clear course data on error
//         }
//     };
    
    
//     const handleClear = () => {
//         sessionStorage.removeItem('userData');
//         setUserData(null);
//         setCourseData(null);  // Clear the course data as well
//         setNetId('');
//     };

//     const renderCreditTable = () => {
//         if (!userData || !userData.tenure) return null;

//         const {
//             credit_teach,
//             credit_thesis,
//             credit_service,
//             credit_award,
//             credit_hiring,
//             credits_current_year,
//             credits_at_year_end
//         } = userData.tenure;

//         return (
//             <div>
//                 <h2>FACULTY NAME</h2>
//                 <table border="1">
//                     <thead>
//                         <tr>
//                             <th>Teaching Credit</th>
//                             <th>Thesis Credit</th>
//                             <th>Service Credit</th>
//                             <th>Award Credit</th>
//                             <th>Hiring Credit</th>
//                             <th>Total Credit</th>
//                             <th>2023-24 Debit/Credit</th>
//                             <th>2024-25 Debit/Credit</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td>{credit_teach || '0.0'}</td>
//                             <td>{credit_thesis || '0.0'}</td>
//                             <td>{credit_service || '0.0'}</td>
//                             <td>{credit_award || '0.0'}</td>
//                             <td>{credit_hiring || '0.0'}</td>
//                             <td>{credits_current_year || '0.0'}</td>
//                             <td>0.0</td> {/* Placeholder for 2023-24 */}
//                             <td>{credits_at_year_end || '0.0'}</td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//         );
//     };

//     const renderCourseTable = () => {
//         if (!courseData) return null; // Check if course data exists

//         const autumnCourses = courseData.filter(course => course.course_qtr === 'Autumn');
//         const winterCourses = courseData.filter(course => course.course_qtr === 'Winter');
//         const springCourses = courseData.filter(course => course.course_qtr === 'Spring');

//         const renderCourseRows = (courses) => {
//             return courses.map((course, index) => (
//                 <tr key={index}>
//                     <td>{course.course_id + course.course_section_letter}</td>
//                     <td>{course.course_days}</td>
//                     <td>{course.course_id_times}</td>
//                 </tr>
//             ));
//         };

//         return (
//             <div>
//                 <h2>2024-2025 Teaching Schedule</h2>
//                 <table border="1">
//                     <thead>
//                         <tr>
//                             <th>Autumn</th>
//                             <th></th>
//                             <th></th>
//                             <th>Winter</th>
//                             <th></th>
//                             <th></th>
//                             <th>Spring</th>
//                             <th></th>
//                             <th></th>
//                         </tr>
//                         <tr>
//                             <th>Course</th>
//                             <th>Day</th>
//                             <th>Time</th>
//                             <th>Course</th>
//                             <th>Day</th>
//                             <th>Time</th>
//                             <th>Course</th>
//                             <th>Day</th>
//                             <th>Time</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td colSpan="3">{renderCourseRows(autumnCourses)}</td>
//                             <td colSpan="3">{renderCourseRows(winterCourses)}</td>
//                             <td colSpan="3">{renderCourseRows(springCourses)}</td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//         );
//     };

//     return (
//         <div>
//             <input
//                 type="text"
//                 value={netId}
//                 onChange={(e) => setNetId(e.target.value)}
//                 placeholder="Enter Net ID"
//             />
//             <button onClick={handleSearch}>Search</button>
//             <button onClick={handleClear}>Clear</button>
//             {error && <p style={{ color: 'red' }}>{error}</p>}
//             {userData && (
//                 <div>
//                     {renderCreditTable()}
//                     {renderCourseTable()} {/* Render the course table */}
//                     <p>If you see any incorrect credit or course details, email <a href="mailto:teachingcredit-support@uw.edu">teachingcredit-support@uw.edu</a> to submit a change request.</p>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Search;






// // COURSE-RELATED TABLE IS EMPTY DOES NOT LOOK FOR COURSE_VIEWABLE DETAILS IN BACKEND
//     const renderCourseTable = () => {
//         if (!userData || !userData.tenure) return null;

//         const autumnCourses = userData.tenure.current_courses.filter(course => course.course_qtr === 'Autumn');
//         const winterCourses = userData.tenure.current_courses.filter(course => course.course_qtr === 'Winter');
//         const springCourses = userData.tenure.current_courses.filter(course => course.course_qtr === 'Spring');

//         const renderCourseRows = (courses) => {
//             return courses.map((course, index) => (
//                 <tr key={index}>
//                     <td>{course.course_id + course.course_section_letter}</td>
//                     <td>{course.course_days}</td>
//                     <td>{course.course_id_times}</td>
//                 </tr>
//             ));
//         };

//         return (
//             <div>
//                 <h2>2024-2025 Teaching Schedule</h2>
//                 <table border="1">
//                     <thead>
//                         <tr>
//                             <th>Autumn</th>
//                             <th></th>
//                             <th></th>
//                             <th>Winter</th>
//                             <th></th>
//                             <th></th>
//                             <th>Spring</th>
//                             <th></th>
//                             <th></th>
//                         </tr>
//                         <tr>
//                             <th>Course</th>
//                             <th>Day</th>
//                             <th>Time</th>
//                             <th>Course</th>
//                             <th>Day</th>
//                             <th>Time</th>
//                             <th>Course</th>
//                             <th>Day</th>
//                             <th>Time</th>
//                         </tr>
//                     </thead>
//                     <tbody>
//                         <tr>
//                             <td colSpan="3">
//                                 {renderCourseRows(autumnCourses)}
//                             </td>
//                             <td colSpan="3">
//                                 {renderCourseRows(winterCourses)}
//                             </td>
//                             <td colSpan="3">
//                                 {renderCourseRows(springCourses)}
//                             </td>
//                         </tr>
//                     </tbody>
//                 </table>
//             </div>
//         );
//     };

//     return (
//         <div>
//             <input
//                 type="text"
//                 value={netId}
//                 onChange={(e) => setNetId(e.target.value)}
//                 placeholder="Enter Net ID"
//             />
//             <button onClick={handleSearch}>Search</button>
//             <button onClick={handleClear}>Clear</button>
//             {error && <p style={{ color: 'red' }}>{error}</p>}
//             {userData && (
//                 <div>
//                     {renderCreditTable()}
//                     {renderCourseTable()}
//                     <p>If you see any incorrect credit or course details, email <a href="mailto:teachingcredit-support@uw.edu">teachingcredit-support@uw.edu</a> to submit a change request.</p>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Search;


// // BEFORE RENDERING TABLES TO BE DISPLAYED
//     return (
//         <div>
//             <input
//                 type="text"
//                 value={netId}
//                 onChange={(e) => setNetId(e.target.value)}
//                 placeholder="Enter Net ID"
//             />
//             <button onClick={handleSearch}>Search</button>
//             <button onClick={handleClear}>Clear</button>
//             {error && <p style={{ color: 'red' }}>{error}</p>} {/* Error message */}
//             {userData && userData.tenure && ( // Check for userData and tenure
//                 <div>
//                     <br></br>
//                     <br></br>
//                     <h2>User Data:</h2>
//                     <h3>{userData.tenure.professor_id}</h3>
//                     <p>Status: {userData.tenure.professor_status}</p>
//                     <p>FTE: {userData.tenure.FTE}</p>
//                     <p>Current Courses: {userData.tenure.current_courses.join(', ')}</p>
//                     <p>Credits Current Year: {userData.tenure.credits_current_year}</p>
//                     <p>Teaching Load: {userData.tenure.teaching_load}</p>
//                     {/* Additional fields can be formatted into tables later */}
//                     <br></br>
//                     <br></br>
//                     <p>If you see any incorrect credit or course details, email <a href="mailto:teachingcredit-support@uw.edu">teachingcredit-support@uw.edu</a> to submit a change request</p>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Search;




    // // WORKING CODE - WITHOUT AUTHENTICATION
    // const handleSearch = async () => {
    //     try {
    //         const response = await axios.get(`http://127.0.0.1:8000/users/${netId}/`);
    //         setUserData(response.data);
    //         setError(null); // Clear any previous errors
            
    //         // Store the fetched user data in session storage
    //         sessionStorage.setItem('userData', JSON.stringify(response.data));
    //     } catch (err) {
    //         console.error('Error fetching user data:', err);
    //         setError('User not found or an error occurred.');
    //         setUserData(null); // Clear previous user data on error
    //     }
    // };
