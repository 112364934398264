import React from 'react';
import Search from './components/Search';

const App = () => {
    return (
        <div>
            <h1>Search for User</h1>
            <Search />
        </div>
    );
};

export default App;